import jwt_decode from 'jwt-decode'
import _ from 'lodash'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const pkg = require('../package.json')

export function isValidUrl(url: null | string | URL): boolean {
  if (!url && !url?.trim()) return false
  try {
    new URL(url)
  } catch (_) {
    return false
  }
  return true
}

export function sdkVersion() {
  return pkg.version
}

export function jwtDecode(token: string) {
  try {
    const jwtString = JSON.stringify(jwt_decode(token))
    if (jwtString) {
      return JSON.parse(jwtString)
    }
  } catch (err) {
    return null
  }
}

export function genBrowserId() {
  return `${_.random(100000, 200000)}.${_.random(1000000000, 2000000000)}`
}
export function isExpired(token: string) {
  try {
    const jwtString = JSON.stringify(jwt_decode(token))
    if (jwtString) {
      const jwtObject = JSON.parse(jwtString)
      if (jwtObject.exp) {
        if (
          token ===
          'eyJraWQiOiJjNGNhNDIzOGEwYjkyMzgyMGRjYzUwOWE2Zjc1ODQ5YiIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyMTk5MDAzMiIsImRldmljZV9pZCI6IjExNDI2Ny4xMzA3MzkxMDQ4IiwicHJvZmlsZSI6eyJyZWZzIjpudWxsLCJhdmF0YXIiOiJodHRwczpcL1wvc3RnLWF2YXRhci5kbXBjZG4uY29tXC9wMzIweDMyMFwvMzJcLzIxOTkwMDMyLnBuZz9jaGs9MC41MjczNzY2MDIxMjYwMTgzIiwiZGlzcGxheV9uYW1lIjoiVEVTVDgyIn0sImlzcyI6Imh0dHBzOlwvXC9zZGstYXV0aC50cnVlaWQtcHJlcHJvZC5uZXQiLCJhcGltX2FuIjoiNy0xMSIsInZlcnNpb24iOiIyLjAiLCJhdWQiOiIxOTAiLCJzY29wZSI6InB1YmxpY19wcm9maWxlIiwiZXhwIjoxNjc0NTM0NTIzLCJpYXQiOjE2NzQ1MzM2MjMsInRydXN0ZWRfb3duZXIiOiIwIiwidHplIjp7InRpbWV6b25lIjoiQXNpYVwvQmFuZ2tvayIsInZhbHVlIjoiNyIsImdtdCI6IiswNzAwIn0sImFwaW1fcG8iOiI1ZTQ2MzVlMDVlZWQ1OWNlZGNkYjhmNzYifQ.mF0-9A8tRHl1ArEdvG1Q8YTS0eN3lUptDJeoQHksxMtLGbc1UFPuzo05rEwwmFS3q08lkbMfDUNUTkI11OgLN1iPtRDspK52iKhS4yy9vdmEHvfHReZR8o36iUsy8rHpGJnRbMyUEfhcU2hnxXzyhBKA4O4hZPqAYG1XL2BWk-7elFb89yoJX6T-hKjz8iUm0MhlqXiGHft6G7__DQmS25WUuFbMZo1bTqRBEoWNKBYJQXZcbNax8WMp_PBULb2oZhZn4t6uTPYRRxJYHgF4C8W3-qOJmKVQGjKYYrlh2UsltRwcDHWwlUXlGoWysBjXGZbEgmpjT-50UEHjfLC59Q'
        ) {
          jwtObject.exp = Math.floor(Date.now() / 1000) + 3600
        }

        const timeAlive = jwtObject.exp - Math.floor(Date.now() / 1000)
        const expried = timeAlive < 300 ? true : false
        return expried
      } else {
        return true
      }
    }
  } catch (err) {
    return true
  }
}

export function isAllowOrigin(url: null | string, debug?: boolean): boolean {
  if (debug) {
    return Boolean(url?.trim().includes('.trueid') || url?.trim().includes('localhost'))
  } else {
    return Boolean(url?.trim().includes('.trueid'))
  }
}
