import { isAllowOrigin, isValidUrl, genBrowserId, jwtDecode, sdkVersion } from './helpers'
/**
 * Object represent any configurable options availble when instantiate TrueIDAuth.class
 *
 * @param {number} client_id - (required) application `client_id`
 * @param {string} redirect_uri - (required) configured `redirect_uri`
 * @param {string?} scope - authentication scope of access_token
 * @param {('token' | 'code')?} response_type - type of your response, default is `token`
 * @param {string?} state - An opaque value used by the client to maintain state between the request and callback
 * @param {('nonprod' | 'prod' | 'alpha')?} env - selected env, should always set to `prod` in production environment
 * @param {('en' | 'th' | 'km' | 'id' | 'my' | 'vi')?} lang - lang of the login page, default is `eng`
 * @param {boolean?} debug - this will use for debug purpse. Should never set to true in prod
 * @param {string?} browser_id - this will use as device_id for browser, format 123456.1234567890
 */
export interface TrueIDAuthConfigType {
  client_id: number | null
  redirect_uri: string | null
  scope?: string
  flow?: 'popup' | 'redirects'
  response_type?: 'token' | 'code'
  state?: string
  env?: 'nonprod' | 'prod' | 'alpha'
  lang?: 'en' | 'th' | 'km' | 'id' | 'my' | 'vi'
  debug?: boolean
  browser_id?: string
  hashed?: string
}

interface AuthServerType {
  baseUrl: string
  myaccountUrl: string
}

export interface VerifyProductAction {
  action: 'close' | 'success'
}

function resolveAuthServer(env?: string): AuthServerType {
  switch (env) {
    case 'prod': {
      return {
        baseUrl: 'https://identity.trueid.net',
        myaccountUrl: 'https://myaccount.trueid.net',
      }
    }
    case 'alpha': {
      return {
        baseUrl: 'https://identity.trueid-alpha.net',
        myaccountUrl: 'https://myaccount.trueid-alpha.net',
      }
    }
    case 'nonprod':
    default: {
      return {
        baseUrl: process.env.REACT_APP_DEBUG_AUTH_SERVER || 'https://identity.trueid-preprod.net',
        myaccountUrl: 'https://myaccount.trueid-preprod.net',
      }
    }
  }
}

/**
 * Object represent an Authentication status of the user
 *
 * @param {string?} access_token : This will be null, if user are not login
 * @param {'not_authorized' | 'connected'} status
 * @param {string} income
 */
export interface AuthStatusType {
  access_token?: string
  status: 'not_authorized' | 'connected'
  income: string
}

/**
 * Authentication client to use connecting to TrueID Authentication System
 *
 * Reference:
 *  * https://oauth.net/2/grant-types/
 *  * https://www.oauth.com/playground/index.html
 *  * https://auth0.com/docs/libraries
 *
 */
class TrueIDAuth {
  private _config: TrueIDAuthConfigType
  private _authServer: AuthServerType
  private _loginWindow?: Window | null
  private _verifyWindow?: Window | null
  private _isAddEventListener?: boolean | null
  private _isAddVerifyEventListener?: boolean | null

  constructor(config: TrueIDAuthConfigType) {
    if (!config.env) {
      config.env = 'nonprod'
    }
    this._isAddEventListener = false
    this._isAddVerifyEventListener = false
    config.debug = Boolean(config.debug)

    if (!config.client_id) throw new Error('missing client_id')
    if (!isValidUrl(config.redirect_uri)) {
      throw new Error('invalid redirect_uri')
    }

    const key = 'browser_id'
    const browser_id = localStorage.getItem(key)
    const now = new Date()
    const ttl = 63113904 // 2 years

    if (!browser_id) {
      const item = {
        value: genBrowserId(),
        expiry: now.getTime() + ttl,
      }
      config.browser_id = item.value
      localStorage.setItem(key, JSON.stringify(item))
    } else {
      const now = new Date()
      const browser_id_val = JSON.parse(browser_id)
      if (now.getTime() > browser_id_val.expiry) {
        localStorage.removeItem(key)
        const item = {
          value: genBrowserId(),
          expiry: now.getTime() + ttl,
        }
        config.browser_id = item.value
        localStorage.setItem(key, JSON.stringify(item))
      } else {
        config.browser_id = browser_id_val.value
      }
    }

    this._config = config

    this._authServer = resolveAuthServer(config.env)
  }

  /** configuration getter method */
  get config(): TrueIDAuthConfigType {
    return this._config
  }

  /**
   * Invoke this function to open an login page. This behavior and parameters will be according to your configuration.
   * If the user is already login, this function will invoke callback with user authentication status.
   */
  login(callback?: (data: AuthStatusType) => unknown): void {
    const targetUrl = new URL(this._authServer.baseUrl)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.Cypress) {
      this._config.flow = 'redirects'
      this._config.redirect_uri = window.location.href
    }

    if (this._config.client_id) {
      targetUrl.searchParams.append('client_id', this._config.client_id.toString())
    }
    if (this._config.redirect_uri) {
      targetUrl.searchParams.append('redirect_uri', this._config.redirect_uri.toString())
    }
    targetUrl.searchParams.append('flow', this._config.flow || 'popup')
    targetUrl.searchParams.append('lang', this._config.lang ?? 'en')
    if (this._config.scope) targetUrl.searchParams.append('scope', this._config.scope)
    targetUrl.searchParams.append('state', this._config.state || Math.floor(Math.random() * 1000).toString())
    if (this._config.browser_id) targetUrl.searchParams.append('browser_id', this._config.browser_id)

    switch (this._config.flow) {
      case 'redirects': {
        window.location.assign(targetUrl.toString())
        break
      }
      case 'popup':
      default: {
        const i = 600,
          o = 600,
          a = document.body.clientWidth / 2 - i / 2,
          s = screen.height / 2 - o / 2
        this._loginWindow = window.open(
          targetUrl.toString(),
          'trueid_login_popup',
          'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
            i +
            ', height=' +
            o +
            ', top=' +
            s +
            ', left=' +
            a
        )
        this._loginWindow?.focus()
        if (this._isAddEventListener === false) {
          window.addEventListener('message', (ev: MessageEvent) => {
            this._isAddEventListener = true
            if (ev.isTrusted && isAllowOrigin(ev.origin, this._config.debug)) {
              if (this._config.debug) console.log('[events]', ev)
              switch (ev.data?.action) {
                case 'login_success': {
                  if (this._loginWindow) {
                    this._loginWindow.close()
                    this._loginWindow = null
                  }
                  window.focus()
                  if (this._config.debug) {
                    console.log(`Login Callback Data : ${JSON.stringify(ev.data)}`)
                  }
                  if (ev.data.access_token) {
                    const jwtExtracted = jwtDecode(ev.data.access_token)
                    const now = new Date()
                    const ttl = 63113904 // 2 years
                    const item = {
                      value: jwtExtracted.device_id,
                      expiry: now.getTime() + ttl,
                    }
                    this.config.browser_id = item.value
                    localStorage.setItem('browser_id', JSON.stringify(item))
                    if (ev.data.hashed) localStorage.setItem('hashed', ev.data.hashed)
                  }
                  if (callback) callback(ev.data)
                }
                default:
                  break
              }
            }
          })
        }

        if (this._config.debug) window.postMessage('initial', '*')
        break
      }
    }
    if (this._config.debug) {
      console.log(this._loginWindow)
      console.log('loginUrl', targetUrl.toString())
    }
  }

  /**
   * Invoke this function to redirect to signout page and terminate your user sessions.
   */
  async logout(callback_uri?: string): Promise<void> {
    const logoutUrl = new URL(this._authServer.baseUrl)
    logoutUrl.pathname = 'signout'

    if (this._config.client_id) {
      logoutUrl.searchParams.append('client_id', this._config.client_id.toString())
    }

    if (this._config.state) {
      logoutUrl.searchParams.append('state', this._config.state)
    }

    if (callback_uri) {
      logoutUrl.searchParams.append('return_to', callback_uri.toString())
    } else if (this._config.redirect_uri) {
      logoutUrl.searchParams.append('redirect_uri', this._config.redirect_uri.toString())
    }

    window.location.assign(logoutUrl.toString())
    if (this._config.debug) {
      console.log('logoutUrl', logoutUrl.toString())
    }
  }

  /**
   * Get current user authentication status of your user.
   */
  async getStatus(): Promise<AuthStatusType> {
    const checkSessionUrl = new URL(
      `${this._authServer.baseUrl}/api/session/check-sso?client_id=${this._config.client_id}&browser_id=${
        this.config.browser_id
      }&ver=${sdkVersion()}`
    )

    const key_hashed = 'hashed'
    const hashed = localStorage.getItem(key_hashed)

    if (hashed) {
      checkSessionUrl.searchParams.append('hashed', hashed.toString())
    }

    const resp = await fetch(checkSessionUrl.toString(), { credentials: 'include' })

    const data = await resp.json()
    if (this._config.debug) {
      console.log(`debug response : ${JSON.stringify(data)}`)
    }
    return { ...data }
  }

  /**
   * Invoke this function to open myaccount verify page with user sessions.
   */

  verifyproduct(type: 'popup' | 'redirect', callback?: (data: VerifyProductAction) => void): void {
    const myaccUrl = new URL(this._authServer.myaccountUrl)
    myaccUrl.pathname = 'true_connect'

    if (this._config.client_id) {
      myaccUrl.searchParams.append('client_id', this._config.client_id.toString())
    }

    if (this._config.redirect_uri) {
      myaccUrl.searchParams.append('redirect_uri', this._config.redirect_uri.toString())
      myaccUrl.searchParams.append('state', this._config.state || Math.floor(Math.random() * 1000).toString())
    }
    myaccUrl.searchParams.append('lang', this._config.lang ?? 'en')
    myaccUrl.searchParams.append('openby', 'websdk')

    if (type === 'popup') {
      if (callback) {
        // use on default popup only
        const i = 450,
          o = 650,
          a = document.body.clientWidth / 2 - i / 2,
          s = screen.height / 2 - o / 2
        this._verifyWindow = window.open(
          myaccUrl.toString(),
          'trueid_verify_popup',
          'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
            i +
            ', height=' +
            o +
            ', top=' +
            s +
            ', left=' +
            a
        )

        if (this._isAddVerifyEventListener === false) {
          window.addEventListener('message', (ev: MessageEvent) => {
            if (this._config.debug) console.log('isAllowOrigin', `${ev.origin} : ${isAllowOrigin(ev.origin)}`)
            if (ev.isTrusted && isAllowOrigin(ev.origin, this._config.debug)) {
              this._isAddVerifyEventListener = true
              if (this._config.debug) console.log('[Verify events]', ev)
              if (this._verifyWindow) {
                this._verifyWindow.close()
                this._verifyWindow = null
              }
              window.focus()
              if (callback) callback(ev.data)
            }
          })
        }
      } else {
        throw new Error('type popup require callback function')
      }
    } else {
      myaccUrl.searchParams.append('opentype', 'redirect')
      // use on redirection mode as full page
      window.location.assign(myaccUrl.toString())
    }

    if (this._config.debug) {
      console.log('myaccUrl', myaccUrl.toString())
      console.log('config', this._config)
    }
  }
}

export default TrueIDAuth
